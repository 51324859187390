const useLocalApi = false;

export const API_BASE = import.meta.env.PROD
  ? "/api"
  : useLocalApi
    ? "http://localhost:3000/api"
    : "https://api.timenjoy.fr/api";

export const AI_BASE = import.meta.env.PROD
  ? "/ai"
  : useLocalApi
    ? "http://localhost:3000/ai"
    : "https://app.konnectz.io/ai";

export const BUCKET_URL = import.meta.env.PROD
  ? "https://pub-919c148094db49fca2690224638d6000.r2.dev"
  : useLocalApi
    ? "https://pub-19feb535712d448d9b9e91057421b76e.r2.dev"
    : "https://pub-919c148094db49fca2690224638d6000.r2.dev";
// export function handleUnauthorized(){}
export const WIDGET_CARD_TITLE_MINIMUM_WIDTH = 226; //px, 250 width - 12*2 padding

export async function logErrorResponse(res: Response) {
  console.log(
    "logErrorResponse",
    res.status,
    await res
      .clone()
      .text()
      .catch(() => null),
  );
  return res;
}

export async function healthCheck() {
  return true;
  // try {
  //   const res = await fetch(`${API_BASE}/health`, {
  //     method: "GET",
  //     signal: AbortSignal.timeout(3_000),
  //   });
  //   console.log(`res.status: ${res.status}`);
  //   return res.ok;
  // } catch (e) {
  //   console.log("timeout");
  //   return false;
  // }
}
