import { WidgetsDTO } from "@/api/definitions";
import { useEffect } from "react";
import { useAsyncValue, useFetcher, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Info, TrendingUp } from "lucide-react";
import { useOptimistic } from "@/hooks/use-optimistic";
import FormState from "@/components/form-state";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

export interface IWidgetRotationProps {}

export default function WidgetRotation(props: IWidgetRotationProps) {
  const widget = useAsyncValue() as WidgetsDTO;
  const [rotationActive, setRotationActive] = useOptimistic(
    widget.rotation_active,
  );
  const [smartReordering, setSmartReordering] = useOptimistic(
    widget.smart_reordering,
    (newValue) => {
      if (newValue && !rotationActive) setRotationActive(true);
    },
  );
  useEffect(() => {
    if (!rotationActive && smartReordering) setSmartReordering(false);
  }, [rotationActive]);
  const fetcher = useFetcher();
  return (
    <Card className="enter animation-fast">
      <CardHeader>
        <CardTitle>Rotation Automatique</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="text-sm leading-6 tracking-wide">
          La rotation automatique permet d'harmoniser la visibilité des annonces
          au sein du carousel. Toutes les 3 heures les annonces en fin de
          carousel sont repositionnées en début de carousel.
        </p>
        <div className="flex items-center gap-3">
          <Switch
            name="rotation_active"
            defaultChecked={widget.rotation_active}
            checked={rotationActive}
            onCheckedChange={setRotationActive}
          />
          <Label htmlFor="rotation_active" className="font-medium">
            {rotationActive ? "Activée" : "Désactivée"}
          </Label>
        </div>
        <Card
          className={cn(
            "enter animation-fast",
            !rotationActive && "enter-opacity-50",
          )}
        >
          <CardHeader>
            <CardTitle className="flex items-center gap-4">
              <TrendingUp className="w-5 h-5" />
              Rotation Intelligente <Badge variant="default">Beta</Badge>
            </CardTitle>
          </CardHeader>
          <CardContent className="pb-6 space-y-6">
            <p className="text-sm leading-6 tracking-wide">
              Ce mode permet de mettre en avant les annonces avec le moins
              d'impressions en les plaçant automatiquement en premier dans le
              widget.
            </p>
            <div className="flex items-center gap-3">
              <Switch
                name="smart_reordering"
                defaultChecked={widget.smart_reordering}
                checked={smartReordering}
                onCheckedChange={setSmartReordering}
              />
              <Label htmlFor="smart_reordering" className="font-medium">
                {smartReordering ? "Activée" : "Désactivée"}
              </Label>
            </div>
          </CardContent>
        </Card>
        <Alert>
          <Info className="h-4 w-4" />
          <AlertDescription>
            Les annonces sponsorisées seront toujours affichées au début du
            carousel.
          </AlertDescription>
        </Alert>
      </CardContent>
      <fetcher.Form method="PUT" action="?index">
        <input type="hidden" name="widgetId" value={widget.id} />
        <CardFooter className="flex justify-end py-4">
          <Button
            type="submit"
            size="sm"
            disabled={
              rotationActive === widget.rotation_active &&
              smartReordering === widget.smart_reordering
            }
          >
            Confirmer
          </Button>
        </CardFooter>
        <FormState
          data={{
            rotation_active: rotationActive,
            smart_reordering: smartReordering,
          }}
        />
      </fetcher.Form>
    </Card>
  );
}
